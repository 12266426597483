import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Input, Row, Form, Button, Col, message } from "antd";
import "./login.css";
import Api from "../../services/api";
import { token } from "../../services/auth";
import { UserContext } from "../../context/UserContext";
import logo from "../../../src/logo.png";
import analytics from "../../../src/analytics.png";
import appstore from "../../../src/app-store.png";
import googleplay from "../../../src/google-play.png";

const novaConta = {
  href: "https://ecomfort.com.br/register",
  target: "_blank",
};
const esqueceuSenha = {
  href: "https://ecomfort.com.br/forgot",
  target: "_blank",
};
const iconIOS = {
  href: "https://apps.apple.com/br/app/e-comfort/id1464231198",
  target: "_blank",
};
const iconAndroid = {
  href: "https://play.google.com/store/apps/details?id=br.com.pixelti.ecomfort&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
  target: "_blank",
};

const LoginPage = ({ match, location }) => {
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);
  let history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  const [login, setLogin] = useState({});

  async function getGateway(id) {
    let gateway = [];
    var res = await Api.getGateway(id);
    if (res.status === 200) {
      gateway = res.data.data[0];
      return gateway;
    } else {
      console.error("erro");
    }
  }

  let okCheckbox = false;

  const handleChange = (event) => {
    if (event.target.checked) {
      okCheckbox = true;
    } else {
      okCheckbox = false;
    }
  };

  async function onSubmit(e) {
    const validUser = new RegExp(
      "^[a-zA-Z0-9.!#$%&`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
    );
    const validPass = new RegExp("^([a-zA-Z0-9!@#$%¨&*]){6,}$");

    setLoadingButton(true);
    var data = {
      email: e.user,
      password: e.password,
      session: {
        deviceId: ".",
        tokenId: ".",
        platform: "web",
      },
    };
    var res = await Api.authentication(data);

    if (validUser.test(e.user)) {
      if (validPass.test(e.password)) {
        if (res.status === 200) {
          let gateway = [];
          setLoadingButton(true);
          token(res.data.data.token);
          user.setUserToken(res.data.data);
          var resData = res.data.data;
          gateway = await getGateway(res.data.data._id);
          history.push(`/${gateway}/overview/`);

          if (okCheckbox) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("gtw", gateway);
            localStorage.setItem("resData", JSON.stringify(resData));
          }
        } else {
          message.error("E-mail ou senha inválidos");
          setLoadingButton(false);
        }
      } else {
        message.error("Senha fora do padrão");
        setLoadingButton(false);
      }
    } else {
      message.error("E-mail fora do padrão");
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    /**
     * Pega a url atual e verificar se possui um JWT nela, seguindo o exemplo:
     * https://dash.ecomfort.com.br/#exemplodeenviojwt
     */
    const url = new URL(window.location.href);
    let urlData = url.hash.replace("#", "");

    if (urlData.length != 0) {
      urlData = urlData.split("/");
      const gtw = urlData[0];
      const JWT = urlData[1];

      localStorage.setItem("token", JWT);
      localStorage.setItem("gtw", gtw);
    }

    const loggedInUser = localStorage.getItem("token");
    if (loggedInUser != null) {
      const foundUser = loggedInUser;
      var resDataData = JSON.parse(localStorage.getItem("resData"));
      let gateway = [];
      gateway = localStorage.getItem("gtw");
      token(foundUser);
      user.setUserToken(resDataData);
      history.push(`/${gateway}/overview/`);
    }
  }, []);

  return (
    <div className="main-container">
      <Card
        className="cardPrincipal"
        style={{
          margin: "0 auto",
          boxShadow: "0 1px 2px #00000",
          borderRadius: "40px",
          border: 0,
        }}
      >
        <div style={{ textAlign: "center", lineHeight: "4" }}>
          <img src={logo} alt="logo" height={"30px"} id="logo"></img>
          <br />
          <span
            style={{
              color: "#fff",
              fontSize: "20px",
              fontWeight: "bold",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          ></span>
          <img src={analytics} alt="analytics" height={"23px"}></img>
        </div>

        <Form onFinish={onSubmit}>
          <Row justify="center" align="middle" style={{ marginBottom: "5px" }}>
            <Col span={20}>
              <div className="loginpage-formlabel">E-mail:</div>
              <br />
              <Form.Item
                name="user"
                label={<div className="loginpage-formlabel"></div>}
                colon={false}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  type="text"
                  name="username"
                  id="username"
                  className="loginpage-input"
                  placeholder="Digite seu e-mail"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center" align="middle" style={{ marginBottom: "5px" }}>
            <Col span={20}>
              <div className="loginpage-formlabel">Senha:</div>
              <br />
              <Form.Item
                name="password"
                label={<div className="loginpage-formlabel"></div>}
                colon={false}
              >
                <Input.Password
                  type="password"
                  name="password"
                  id="password"
                  className="loginpage-inputpass"
                  placeholder="Digite sua senha"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="item">
            <div className="checkbox-circle">
              <input
                type="checkbox"
                value={isChecked}
                onChange={handleChange}
                id="checkbox-circle1"
                name="checkbox-circle1"
              />
              <label htmlFor="checkbox-circle1" id="fonte">
                Manter conectado
              </label>
            </div>
          </div>

          <Row justify="center" align="middle">
            <Col span={8}>
              <Button
                type="primary"
                htmlType="submit"
                className="loginpage-loginbtn"
                loading={loadingButton}
              >
                Entrar
              </Button>
            </Col>
          </Row>
        </Form>

        <div className="conta">
          <a {...novaConta}>NOVA CONTA</a>
          <br />
          <a {...esqueceuSenha}>Esqueceu a senha?</a>
        </div>

        <div className="apps">
          <a {...iconIOS}>
            <img
              src={appstore}
              alt="App Store"
              height={"35px"}
              id="appstore"
            ></img>
          </a>
          <a {...iconAndroid}>
            <img
              src={googleplay}
              alt="Google Play"
              height={"35px"}
              id="googleplay"
            ></img>
          </a>
        </div>
      </Card>
    </div>
  );
};

export default LoginPage;
