import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Row, Col, notification } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import "./environmentPage.css";
import Api from "../../../services/api";
import EnvironmentGauge from "../../Gauge/environmentGauge";
import EnvironmentChart from "../../Charts/environmentChart";
import { UserContext } from "../../../context/UserContext";
import ReactLoading from "react-loading";

const { TabPane } = Tabs;

const EnvironmentsPage = () => {
  let { idGateway } = useParams();
  let { idAmbiente } = useParams();
  const ambiente = idAmbiente.replaceAll("%20", " ");
  const [done, setDone] = useState(false);
  const [singleEnvironment, setsingleEnvironment] = useState(["vazio"]);
  const [singleEnvironmentName, setsingleEnvironmentName] = useState([]);
  const [idTemperatures, setIdTemperatures] = useState([]);
  const [idMetering, setIdMetering] = useState([]);
  const [idIlluminances, setIdIlluminances] = useState([]);
  const [idOccupancy, setIdOccupancy] = useState([]);
  const [idSmartPlug, setIdSmartPlug] = useState([]);
  const [idWater, setIdWater] = useState([]);
  const [idSmartSwitch, setIdSmartSwitch] = useState([]);
  const [idSmartPlugPlus, setIdSmartPlugPlus] = useState([]);
  const [idOpenClosed, setIdOpenClosed] = useState([]);
  const [idLed, setIdLed] = useState([]);
  const [tempHumInfo, setTempHumInfo] = useState([
    {
      minTemp: "0",
      maxTemp: "0",
      mediaTemp: "0",
      minHum: "0",
      maxHum: "0",
      mediaHum: "0",
    },
  ]);
  const [meteringInfo, setMeteringInfo] = useState([
    { min: "0", max: "0", media: "0" },
  ]);
  const [illuminanceInfo, setIlluminanceInfo] = useState([
    { min: "0", max: "0", media: "0" },
  ]);
  const [idSmartPulse, setIdSmartPulse] = useState([]);

  const user = useContext(UserContext);

  const setEnvironments = async () => {
    const res = await Api.getAllEnvironments(idGateway);
    if (res.status === 200) {
      res.data.data.map((environments) => {
        if (environments.name == ambiente) {
          setsingleEnvironment([environments]);
          setsingleEnvironmentName(environments.name);
        }
      });
    }
    if (singleEnvironment !== "vazio") {
      deviceById();
    }
  };

  function deviceById() {
    let temperatures = [];
    let metering = [];
    let smartPlug = [];
    let illuminances = [];
    let occupancy = [];
    let water = [];
    let smartPlugPlus = [];
    let smartSwitch = [];
    let openClosed = [];
    let led = [];
    let smartPulse = [];

    singleEnvironment.map((ambiente) => {
      ambiente.devices.map((device) => {
        if (device.devId === "770" && device.status === "ONLINE") {
          temperatures.push(device.ieeeAddr);
        }
        if (device.devId === "81" && device.status === "ONLINE") {
          smartPlug.push(device.ieeeAddr);
          metering.push(device.ieeeAddr);
          smartPulse.push(device.ieeeAddr);
        }
        if (device.devId === "771" && device.status === "ONLINE") {
          metering.push(device.ieeeAddr);
          smartPlugPlus.push(device.ieeeAddr);
        }
        if (device.devId === "262" && device.status === "ONLINE") {
          illuminances.push(device.ieeeAddr);
        }
        if (device.devId === "263" && device.status === "ONLINE") {
          occupancy.push(device.ieeeAddr);
        }
        if (device.devId === "264" && device.status === "ONLINE") {
          smartSwitch.push(device.ieeeAddr);
        }
        if (device.devId === "12" && device.status === "ONLINE") {
          openClosed.push(device.ieeeAddr);
        }
        if (device.devId === "774" && device.status === "ONLINE") {
          water.push(device.ieeeAddr);
        }
        if (device.devId === "261" && device.status === "ONLINE") {
          led.push(device.ieeeAddr);
        }
      });
      setIdMetering(metering);
      setIdTemperatures(temperatures);
      setIdIlluminances(illuminances);
      setIdOccupancy(occupancy);
      setIdWater(water);
      setIdSmartPlugPlus(smartPlugPlus);
      setIdSmartPlug(smartPlug);
      setIdSmartSwitch(smartSwitch);
      setIdOpenClosed(openClosed);
      setIdLed(led);
      setIdSmartPulse(smartPulse);

      setDone(true);
    });

    if (temperatures.length !== 0) {
      getTemperatures(temperatures);
    }
    if (metering.length !== 0) {
      getMetering(metering);
    }
    if (illuminances.length !== 0) {
      getIlluminances(illuminances);
    }
  }

  async function getTemperatures(idTemperatures) {
    setDone(true);
    let somaTemp = 0;
    let somaHum = 0;
    let mediaTemp = 0;
    let mediaHum = 0;
    let arrayTemp = [];
    let arrayHum = [];
    let arrayInfo = [];

    for (const device of idTemperatures) {
      const res = await Api.getTemperatureAverage(device, idGateway);
      if (res.status === 200) {
        for (const dataObj of res.data.data.slice(0, 24)) {
          somaTemp = somaTemp + dataObj.value;
          arrayTemp.push(dataObj.value);
        }
      }
      var minTemp = Math.min(...arrayTemp);
      var maxTemp = Math.max(...arrayTemp);
    }

    for (const device of idTemperatures) {
      const res = await Api.getHumidityAverage(device, idGateway);
      if (res.status === 200) {
        for (const dataObj of res.data.data.slice(0, 24)) {
          somaHum = somaHum + dataObj.value;
          arrayHum.push(dataObj.value);
        }
      }
      var minHum = Math.min(...arrayHum);
      var maxHum = Math.max(...arrayHum);
    }
    mediaHum = somaHum / (24 * idTemperatures.length);
    mediaTemp = somaTemp / (24 * idTemperatures.length);

    arrayInfo.push({
      minTemp: minTemp,
      maxTemp: maxTemp,
      mediaTemp: mediaTemp,
      minHum: minHum,
      maxHum: maxHum,
      mediaHum: mediaHum,
    });
    setTempHumInfo(arrayInfo);
  }

  async function getMetering(idMetering) {
    setDone(true);
    let valor = 0;
    let arrayMetering = [];
    let arrayInfo = [];

    for (const device of idMetering) {
      const res = await Api.getMeteringBySensorId(device, idGateway);
      if (res.status === 200) {
        valor = valor + res.data.data[0].value;
        for (const dataObj of res.data.data.slice(0, 24)) {
          arrayMetering.push(dataObj.value);
        }
      }
      var minMetering = Math.min(...arrayMetering);
      var maxMetering = Math.max(...arrayMetering);
    }

    arrayInfo.push({ min: minMetering, max: maxMetering, media: valor / 1000 });
    setMeteringInfo(arrayInfo);
  }

  async function getIlluminances(idIlluminances) {
    setDone(true);
    let soma = 0;
    let arrayIllum = [];
    let mediaIllum = 0;
    let arrayInfo = [];

    for (const device of idIlluminances) {
      const res = await Api.getIlluminanceAverage(device, idGateway);
      if (res.status === 200) {
        for (const dataObj of res.data.data.slice(0, 24)) {
          soma = soma + dataObj.value;
          arrayIllum.push(dataObj.value);
        }
        mediaIllum = soma / (24 * idIlluminances.length);
        var minIllum = Math.min(...arrayIllum);
        var maxIllum = Math.max(...arrayIllum);
        arrayInfo.push({ min: minIllum, max: maxIllum, media: mediaIllum });
        setIlluminanceInfo(arrayInfo);
      }
    }
  }

  const setCharts = (props) => {
    if (props === "climatizacao") {
      if (idTemperatures.length !== 0) {
        return [
          <div>
            <span>Temperatura</span>
            <EnvironmentChart
              param="temperature"
              param2={idTemperatures}
            ></EnvironmentChart>
          </div>,
          <div>
            <span>Umidade</span>
            <EnvironmentChart
              param="humidity"
              param2={idTemperatures}
            ></EnvironmentChart>
          </div>,
        ];
      }
    }
    if (props === "iluminacao") {
      if (idIlluminances.length !== 0) {
        return (
          <div>
            <span>Luminosidade</span>
            <EnvironmentChart
              param="illuminance"
              param2={idIlluminances}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idOccupancy.length !== 0) {
        return (
          <div>
            <span>Sensor de Presença</span>
            <EnvironmentChart
              param="occupancy"
              param2={idOccupancy}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idSmartSwitch.length !== 0) {
        return (
          <div>
            <span>Smart Switch</span>
            <EnvironmentChart
              param="smartSwitch"
              param2={idSmartSwitch}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idLed.length !== 0) {
        return (
          <div>
            <span>Fita de LED</span>
            <EnvironmentChart param="led" param2={idLed}></EnvironmentChart>
          </div>
        );
      }
    }

    if (props === "protecao") {
      if (idOpenClosed.length !== 0) {
        return (
          <div>
            <span>Abertura/Fechamento</span>
            <EnvironmentChart
              param="openClosed"
              param2={idOpenClosed}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idOccupancy.length !== 0) {
        return (
          <div>
            <span>Sensor de Presença</span>
            <EnvironmentChart
              param="occupancy"
              param2={idOccupancy}
            ></EnvironmentChart>
          </div>
        );
      }
    }

    if (props === "controleAcesso") {
      if (idOpenClosed.length !== 0) {
        return (
          <div>
            <span>Abertura/Fechamento</span>
            <EnvironmentChart
              param="openClosed"
              param2={idOpenClosed}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idOccupancy.length !== 0) {
        return (
          <div>
            <span>Sensor de Presença</span>
            <EnvironmentChart
              param="occupancy"
              param2={idOccupancy}
            ></EnvironmentChart>
          </div>
        );
      }
    }

    if (props === "controleDomestico") {
      if (idSmartPlug.length !== 0) {
        return (
          <div>
            <span>Energia</span>
            <EnvironmentChart
              param="metering"
              param2={idMetering}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idSmartPlugPlus.length !== 0) {
        return (
          <div>
            <span>Smart Plug Plus</span>
            <EnvironmentChart
              param="smartPlugPlus"
              param2={idSmartPlugPlus}
            ></EnvironmentChart>
          </div>
        );
      }
      if (idWater.length !== 0) {
        return (
          <div>
            <span>Sensor de água</span>
            <EnvironmentChart param="water" param2={idWater}></EnvironmentChart>
          </div>
        );
      }
    }
  };

  const openNotification = () => {
    const args = {
      message: "Dica para seu ambiente",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam felis lorem, sodales eu lectus vel, lacinia rhoncus mi. Aenean condimentum odio a convallis ornare. Etiam ornare dignissim nibh, non consequat est venenatis sed. Aenean mattis viverra est, sit amet egestas velit blandit in. Ut nec lectus eu ipsum blandit porttitor ac cursus mauris. Donec consequat aliquet massa, id convallis tortor efficitur sit ame.",
      duration: 0,
      placement: "bottomRight",
    };
    notification.open(args);
  };

  useEffect(() => {
    setEnvironments();
    if (window.localStorage) {
      if (!localStorage.getItem("firstLoad")) {
        localStorage["firstLoad"] = true;
        window.location.reload();
      } else localStorage.removeItem("firstLoad");
    }
  }, [idAmbiente]);

  useEffect(() => {
    setTimeout(() => {
      if (singleEnvironment !== "vazio") {
        deviceById();
      }
    }, 4000);
  }, [singleEnvironment]);

  // useEffect(() => {
  //     if(done === true){
  //         openNotification()
  //     }

  // },[done]);

  return (
    <div className="environmentPage">
      {done === false ? (
        <ReactLoading id="loading" type={"bars"} color={"white"} />
      ) : (
        <div>
          <span className="environmentName">{singleEnvironmentName}</span>
          <div className="healthy-container">
            <div className="healthy">
              <Row>
                {idTemperatures.length !== 0
                  ? [
                      <Col span={6} className="Gauge">
                        <EnvironmentGauge
                          devId="770"
                          type="temp"
                          value={tempHumInfo[0].mediaTemp}
                          min={tempHumInfo[0].minTemp}
                          max={tempHumInfo[0].maxTemp}
                        ></EnvironmentGauge>
                      </Col>,
                      <Col span={6} className="Gauge">
                        <EnvironmentGauge
                          devId="770"
                          type="hum"
                          value={tempHumInfo[0].mediaHum}
                          min={tempHumInfo[0].minHum}
                          max={tempHumInfo[0].maxHum}
                        ></EnvironmentGauge>
                      </Col>,
                    ]
                  : null}
                {idMetering.length !== 0 ? (
                  <Col span={6} className="Gauge">
                    <EnvironmentGauge
                      devId="81"
                      value={meteringInfo[0].media}
                      min={meteringInfo[0].min}
                      max={meteringInfo[0].max}
                    ></EnvironmentGauge>
                  </Col>
                ) : null}
                {idIlluminances.length !== 0 ? (
                  <Col span={6} className="Gauge">
                    <EnvironmentGauge
                      devId="262"
                      type="temp"
                      value={illuminanceInfo[0].media}
                      min={illuminanceInfo[0].min}
                      max={illuminanceInfo[0].max}
                    ></EnvironmentGauge>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>

          <div className="categories-container">
            <div className="categories">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Climatização" key="1">
                  {idTemperatures.length !== 0 ? (
                    <div className="charts">{setCharts("climatizacao")}</div>
                  ) : (
                    <div className="noDevice">
                      <Row>
                        <FrownOutlined
                          style={{
                            fontSize: "8rem",
                            color: "#969696",
                            strokeWidth: "45",
                            stroke: "rgb(39 45 51)",
                          }}
                        />
                      </Row>
                      <Row>
                        <span>
                          Ops, você não tem nenhum sensor de Climatização
                        </span>
                      </Row>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Iluminação" key="2">
                  {idIlluminances.length !== 0 ||
                  idOccupancy.length !== 0 ||
                  idSmartSwitch.length !== 0 ||
                  idLed.length !== 0 ? (
                    <div className="charts">{setCharts("iluminacao")}</div>
                  ) : (
                    <div className="noDevice">
                      <Row>
                        <FrownOutlined
                          style={{
                            fontSize: "8rem",
                            color: "#969696",
                            strokeWidth: "45",
                            stroke: "rgb(39 45 51)",
                          }}
                        />
                      </Row>
                      <Row>
                        <span>
                          Ops, você não tem nenhum sensor de Iluminação
                        </span>
                      </Row>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Proteção" key="3">
                  {idOpenClosed.length !== 0 || idOccupancy.length !== 0 ? (
                    <div className="charts">{setCharts("protecao")}</div>
                  ) : (
                    <div className="noDevice">
                      <Row>
                        <FrownOutlined
                          style={{
                            fontSize: "8rem",
                            color: "#969696",
                            strokeWidth: "45",
                            stroke: "rgb(39 45 51)",
                          }}
                        />
                      </Row>
                      <Row>
                        <span>Ops, você não tem nenhum sensor de Proteção</span>
                      </Row>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Controle de Acesso" key="4">
                  {idOccupancy.length !== 0 || idOpenClosed.length !== 0 ? (
                    <div className="charts">{setCharts("controleAcesso")}</div>
                  ) : (
                    <div className="noDevice">
                      <Row>
                        <FrownOutlined
                          style={{
                            fontSize: "8rem",
                            color: "#969696",
                            strokeWidth: "45",
                            stroke: "rgb(39 45 51)",
                          }}
                        />
                      </Row>
                      <Row>
                        <span>
                          Ops, você não tem nenhum sensor de Controle de Acesso
                        </span>
                      </Row>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Controle Doméstico" key="5">
                  {idSmartPlug.length !== 0 ||
                  idSmartPlugPlus.length !== 0 ||
                  idWater.length !== 0 ? (
                    <div className="charts">
                      {setCharts("controleDomestico")}
                    </div>
                  ) : (
                    <div className="noDevice">
                      <Row>
                        <FrownOutlined
                          style={{
                            fontSize: "8rem",
                            color: "#969696",
                            strokeWidth: "45",
                            stroke: "rgb(39 45 51)",
                          }}
                        />
                      </Row>
                      <Row>
                        <span>
                          Ops, você não tem nenhum sensor de Controle Doméstico
                        </span>
                      </Row>
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnvironmentsPage;
