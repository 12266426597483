import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { format, parseISO } from "date-fns";
import Api from "../../services/api";
import {
  calc,
  handleRoundTime,
  handleValueAndTimeLastTwelveHours,
  hoursForComparisons,
} from "./holders";

const LineChart = (props) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);
  let { idGateway } = useParams();

  let { h1, h2, h3, h4, h5, h6, h7, h8, h9, h10, h11, h12 } =
    hoursForComparisons();

  async function fetchData(apiMethod, paramName, dataName, formatter) {
    if (loading) return;
    setLoading(true);

    let time = [];
    let data = [];
    const res = await apiMethod(props.param2.ieeeAddr, idGateway);

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);
        if (receivedTime >= twelveHoursBefore) {
          data.push(formatter(dataObj));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, data] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      data,
      paramName === "humidity"
    );

    setSeries([
      {
        name: dataName,
        data: data,
      },
    ]);

    setOptions({
      markers: {
        size: [4, 7],
        colors: "#6b81c7",
        strokeColors: "#7575754e",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
      },
      grid: {
        strokeDashArray: 0,
        position: "back",
        borderColor: "#b5bbb645",
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ["#6b81c7"],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: paramName === "humidity" ? 6 : 12,
        min: 0,
        max: paramName === "humidity" ? 100 : undefined,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + (paramName === "humidity" ? "%" : "ºC");
          },
        },
      },
      fill: {
        colors: ["#6b81c7"],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Sem dados no momento...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });

    setLoading(false);
  }

  useEffect(() => {
    let apiMethod;
    let paramName;
    let dataName;
    let formatter = (dataObj) => dataObj.value;

    switch (props.param) {
      case "temperature":
        apiMethod = Api.getTemperatureBySensorId;
        paramName = "temperature";
        dataName = "Temperatura";
        break;
      case "humidity":
        apiMethod = Api.getHumidityBySensorId;
        paramName = "humidity";
        dataName = "Umidade";
        break;
      case "illuminance":
        apiMethod = Api.getIlluminanceBySensorId;
        paramName = "illuminance";
        dataName = "Luminosidade";
        break;
      case "metering":
        apiMethod = Api.getMeteringBySensorId;
        paramName = "metering";
        dataName = "Energia";
        formatter = (dataObj) => parseInt(dataObj.usageValue);
        break;
      case "intensity":
        apiMethod = Api.getIntensityBySensorId;
        paramName = "intensity";
        dataName = "Intensidade";
        break;
      case "smartPlugPlus":
        apiMethod = Api.getSmartPlugPlusBySensorId;
        paramName = "smartPlugPlus";
        dataName = "Energia";
        formatter = (dataObj) => parseInt(dataObj.usageValue);
        break;
      case "occupancy":
        apiMethod = Api.getOccupancyBySensorId;
        paramName = "occupancy";
        dataName = "Situação";
        formatter = (dataObj) => dataObj.wasOn;
        break;
      default:
        return;
    }

    fetchData(apiMethod, paramName, dataName, formatter);
    const timer = setInterval(() => {
      fetchData(apiMethod, paramName, dataName, formatter);
    }, 300000);

    return () => clearInterval(timer);
  }, [props.param, props.param2.ieeeAddr, idGateway]);

  return (
    <div>
      <div>
        <ReactApexChart options={options} series={series} type="area" />
      </div>
    </div>
  );
};

export default LineChart;
