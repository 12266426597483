import React, { useContext, useEffect, useState } from "react";
import Api from "../../../services/api";
import { getToken } from "../../../services/auth";
import { Menu, Dropdown, Button } from "antd";
import "./sider.css";
import { UserContext } from "../../../context/UserContext";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { DownOutlined } from "@ant-design/icons";
import ecomfort from "../../../../src/ecomfort.png";
import { useHistory } from "react-router-dom";
import gtw from "../../../../src/gtw-icon.svg";

export default function DashboardSider() {
  const [environments, setEnvironments] = useState([]);
  const [gateway, setGateway] = useState([]);
  const [gatewayName, setGatewayName] = useState([]);
  let { idGateway } = useParams();
  let history = useHistory();
  const user = useContext(UserContext);

  async function getUser() {
    const allGateways = [];
    const decoded = jwt_decode(getToken());
    const res = await Api.getUser(decoded._id).catch((err) => {
      const responseStatus = err.response.status;
      if (responseStatus == 401) {
        localStorage.removeItem("resData");
        localStorage.removeItem("token");
        localStorage.removeItem("gtw");
        history.push("/");
      }
    });
    if (res != undefined) {
      if (res.status === 200) {
        user.setUserName(res.data.data.name);
        user.setUserLastName(res.data.data.lastName);
        user.setUserImage(res.data.data.profileImage);

        res.data.data._gateways.map((gateways) => {
          allGateways.push(gateways);
        });
        setGateway(allGateways);

        allGateways.map((gateway) => {
          if (idGateway == gateway._id) {
            setGatewayName(gateway.name);
          }
        });
      }
    }
  }

  async function getEnvironment() {
    var environmentsTemp = [];
    var activeEnvironment = [];
    const res = await Api.getAllEnvironments(idGateway);
    if (res.status === 200) {
      res.data.data.map((ambientes) => {
        environmentsTemp.push(ambientes);
      });

      environmentsTemp.map((ambientesComDevice) => {
        ambientesComDevice.devices.map((onlyActives) => {
          if (onlyActives.status == "ONLINE") {
            activeEnvironment.push(ambientesComDevice);
          }
        });
      });

      const uniqueEnvironments = Array.from(
        new Set(activeEnvironment.map((a) => a.id))
      ).map((id) => {
        return activeEnvironment.find((a) => a.id === id);
      });

      setEnvironments(uniqueEnvironments);
    }
  }

  useEffect(() => {
    getEnvironment();
    getUser();
  }, []);

  const menuGateway = (
    <Menu>
      {gateway.map((gateways, index) => (
        <Menu.Item key={index}>
          <a href={`/${gateways._id}/overview`} style={{ color: "#000000" }}>
            {gateways.name}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="sider">
      <Dropdown
        overlay={menuGateway}
        trigger={["click"]}
        className="gtw1"
        placement="bottomRight"
      >
        <button className="gtw-btn">
          <img src={gtw} alt="Gateway:" className="gtw" height="40" />

          <span className="dropdown-link">
            {gatewayName}
            <DownOutlined />
          </span>
        </button>
      </Dropdown>
    </div>
  );
}
