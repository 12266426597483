import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import Api from "../../services/api";
import ReactApexChart from "react-apexcharts";
import "./environmentChart.css";

const EnvironmentChart = (props) => {
  const [series, setSeries] = useState([{}]);
  const [options, setOptions] = useState({});
  let { idGateway } = useParams();

  async function getTemperature() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getTemperatureAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: [
          "#6b81c7",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
      },
      tooltip: {
        marker: {
          fillColors: [
            "#6b81c7",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      legend: {
        markers: {
          fillColors: [
            "#6b81c7",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#6b81c7",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },

      fill: {
        colors: [
          "#6b81c7",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getHumidity() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getHumidityAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }

        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: [
          "#2756ec",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
      },
      tooltip: {
        marker: {
          fillColors: [
            "#2756ec",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      legend: {
        markers: {
          fillColors: [
            "#2756ec",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#2756ec",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      fill: {
        colors: [
          "#2756ec",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getIlluminance() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getIlluminanceAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: [
          "#eeb89d",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
      },

      tooltip: {
        marker: {
          fillColors: [
            "#eeb89d",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      legend: {
        markers: {
          fillColors: [
            "#eeb89d",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#eeb89d",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      fill: {
        colors: [
          "#eeb89d",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getIntensity() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getIntensityAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: [
          "#e0e052",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
      },

      tooltip: {
        marker: {
          fillColors: [
            "#e0e052",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      legend: {
        markers: {
          fillColors: [
            "#e0e052",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#e0e052",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      fill: {
        colors: [
          "#e0e052",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getMetering() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getMeteringAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: [
          "rgb(102, 204, 102)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
      },
      legend: {
        markers: {
          fillColors: [
            "rgb(102, 204, 102)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "rgb(102, 204, 102)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return value + " w/h";
          },
        },
        marker: {
          fillColors: [
            "rgb(102, 204, 102)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      fill: {
        colors: [
          "rgb(102, 204, 102)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getSmartPlugPlus() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getSmartPlugPlusAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: [
          "#4ef997",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        curve: "smooth",
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return value / 1000 + "kw/h";
          },
        },
        marker: {
          fillColors: [
            "#4ef997",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      legend: {
        markers: {
          fillColors: [
            "#4ef997",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#4ef997",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      fill: {
        colors: [
          "#4ef997",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getOccupancy() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getOccupancyAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: [
          "rgba(176, 107, 199, 0.8)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      xaxis: {
        categories: time,
      },
      legend: {
        markers: {
          fillColors: [
            "#4ef997",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#4ef997",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        forceNiceScale: true,
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return "Ocupado por " + value + " min";
            } else {
              return "Desocupado";
            }
          },
        },
        marker: {
          fillColors: [
            "#4ef997",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      fill: {
        colors: ["rgb(176, 107, 199)"],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getWater() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getWaterAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: [
          "#6bb5c7",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      xaxis: {
        categories: time,
      },
      legend: {
        markers: {
          fillColors: [
            "#6bb5c7",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#6bb5c7",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        forceNiceScale: true,
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return "Com água por " + value + " min";
            } else {
              return "Sem água";
            }
          },
        },

        marker: {
          fillColors: [
            "#6bb5c7",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      fill: {
        colors: [
          "#6bb5c7",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getSmartSwitch() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getSmartSwitchAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: [
          "#61ac53",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      xaxis: {
        categories: time,
      },
      legend: {
        markers: {
          fillColors: [
            "#61ac53",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "#61ac53",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        forceNiceScale: true,
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return "Acionado por " + value + " min";
            } else {
              return "Desligado";
            }
          },
        },
        marker: {
          fillColors: [
            "#61ac53",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      fill: {
        colors: [
          "#61ac53",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  async function getOpenClosed() {
    let time = [];
    let value = [];
    let name = [];
    let newSeries = [];

    for (const device of props.param2) {
      const res = await Api.getOpenClosedAverage(device, idGateway);
      if (res.status === 200) {
        name.push(res.data.data[0].name);
        for (const dataObj of res.data.data.slice(0, 24)) {
          value.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
        value.reverse();
        newSeries.push({ name: name, data: value });
        value = [];
        name = [];
      }
      time.reverse();
    }

    setSeries(newSeries);

    setOptions({
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: [
          "rgba(199, 107, 144, 0.8)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      xaxis: {
        categories: time,
      },
      legend: {
        markers: {
          fillColors: [
            "rgba(199, 107, 144, 0.8)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      markers: {
        colors: [
          "rgba(199, 107, 144, 0.8)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        forceNiceScale: true,
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return "Aberto por " + value + " min";
            } else {
              return "Fechado";
            }
          },
        },
        marker: {
          fillColors: [
            "rgba(199, 107, 144, 0.8)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
          ],
        },
      },
      fill: {
        colors: [
          "rgba(199, 107, 144, 0.8)",
          "rgb(0, 143, 251)",
          "rgb(0, 227, 150)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
        ],
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: "Loading...",
        style: {
          color: "#ffffff",
        },
      },
      chart: {
        foreColor: "#838181",
      },
    });
  }

  useEffect(() => {
    switch (props.param) {
      case "temperature": {
        getTemperature();
        const timer = setInterval(() => {
          getTemperature();
        }, 3600000);
        return () => clearInterval(timer);
      }
      case "humidity": {
        getHumidity();
        const timer = setInterval(() => {
          getHumidity();
        }, 3600000);
        return () => clearInterval(timer);
      }
      case "illuminance": {
        getIlluminance();
        const timer = setInterval(() => {
          getIlluminance();
        }, 3600000);
        return () => clearInterval(timer);
      }
      case "metering": {
        getMetering();
        const timer = setInterval(() => {
          getMetering();
        }, 3600000);
        return () => clearInterval(timer);
      }

      case "smartPlugPlus": {
        getSmartPlugPlus();
        const timer = setInterval(() => {
          getSmartPlugPlus();
        }, 3600000);
        return () => clearInterval(timer);
      }

      case "occupancy": {
        getOccupancy();
        const timer = setInterval(() => {
          getOccupancy();
        }, 3600000);
        return () => clearInterval(timer);
      }

      case "water": {
        getWater();
        const timer = setInterval(() => {
          getWater();
        }, 3600000);
        return () => clearInterval(timer);
      }

      case "smartSwitch": {
        getSmartSwitch();
        const timer = setInterval(() => {
          getSmartSwitch();
        }, 3600000);
        return () => clearInterval(timer);
      }

      case "openClosed": {
        getOpenClosed();
        const timer = setInterval(() => {
          getOpenClosed();
        }, 3600000);
        return () => clearInterval(timer);
      }

      case "led": {
        getIntensity();
        const timer = setInterval(() => {
          getIntensity();
        }, 3600000);
        return () => clearInterval(timer);
      }
    }
  }, []);

  return (
    <div className="chart">
      <div>
        {props.param === "occupancy" ||
        props.param === "openClosed" ||
        props.param === "water" ||
        props.param === "smartSwitch" ? (
          <ReactApexChart
            series={series}
            options={options}
            type="bar"
          ></ReactApexChart>
        ) : (
          <ReactApexChart series={series} options={options} type="area" />
        )}
      </div>
    </div>
  );
};

export default EnvironmentChart;
