import axios from "axios";
import { getToken } from "./auth";
import { analyticsAPI, ecomfortAPI } from "./services";

export default class Api {
  static async authentication(body) {
    try {
      const res = await axios.post(`${ecomfortAPI}user/auth`, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  // GET Example: /ecomfort/analytics/gateways/:serialNumber?take=100&filter={"ieeeAddr": "0x804b50fffec5a70f", "createdAt": {"$gte": "2024-10-16T13:00:00.000Z"}}&dataType=batteries

  static async getTemperatureBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "temperatures",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getHumidityBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "humidities",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getIlluminanceBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "illuminances",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOnOffBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getMeteringBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "meterings",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getIntensityBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "intensities",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOccupancyBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "occupancies",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOpenClosedBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOpenClosedBySensorId2(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartPulse(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 265 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getChaveUmQuarto(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 772 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSirene(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 2 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartLock(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 10 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getLed(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 261 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getLamp(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 258 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getWaterBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 774 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getWaterBySensorId2(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 774 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartPlugPlusBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "meterings",
          filter: JSON.stringify({ ieeeAddr: id, devId: 771 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartSwitchBySensorId2(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 264 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartSwitchBySensorId(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 264 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getTemperatureAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "temperatures",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getHumidityAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "humidities",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getIlluminanceAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "illuminances",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getMeteringAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "meterings",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getIntensityAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "intensities",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOccupancyAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "occupancies",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOpenClosedAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getWaterAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 774 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartPlugPlusAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "meterings",
          filter: JSON.stringify({ ieeeAddr: id, devId: 771 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getSmartSwitchAverage(id, idGateway) {
    try {
      const res = await axios.get(`${analyticsAPI}${idGateway}`, {
        params: {
          dataType: "onoffs",
          filter: JSON.stringify({ ieeeAddr: id, devId: 264 }),
          take: 12,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getAllEnvironments(idGateway) {
    try {
      const res = await axios.get(`${ecomfortAPI}gateway/${idGateway}/device`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      const listDevice = this.filterDevices(res.data.data);
      const listEnv = this.formatEnvs(listDevice);

      const fullResponde = this.addDevicesToEnv(listDevice, listEnv);

      return { data: { data: fullResponde }, status: 200 };
    } catch (error) {
      return error;
    }
  }

  static async getSingleEnvironment(idGateway, idEnvironment) {
    try {
      const res = await axios.get(
        `${ecomfortAPI}gateway/${idGateway}/environment/${idEnvironment}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getDevices(idGateway) {
    try {
      const res = await axios.get(`${ecomfortAPI}gateway/${idGateway}/device`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static async getGateway(id) {
    try {
      const res = await axios.get(`${ecomfortAPI}user/${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const gtwId = res.data.data._gateways.map((gtw) => gtw._id);
      return { data: { data: gtwId }, status: res.status };
    } catch (error) {
      return error;
    }
  }

  static async getUser(id) {
    try {
      const res = axios.get(`${ecomfortAPI}user/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  }

  static getCidAndType(attributes, key) {
    if (attributes) {
      const objContainer = Object.getOwnPropertyNames(attributes);
      let holder = [];
      objContainer.forEach((data) => {
        let splitValue = data.split("#");
        if (key === 1) {
          holder.push({
            type: splitValue[key],
          });
        } else if (key === 0) {
          holder.push({
            cId: splitValue[key],
          });
        }
      });

      if (key === 1) {
        return holder.filter(
          (data, index, array) =>
            array.findIndex((value) => value.type === data.type) === index
        );
      } else if (key === 0) {
        return holder.filter(
          (data, index, array) =>
            array.findIndex((value) => value.cId === data.cId) === index
        );
      }
    }
    return null;
  }

  static filterDevices(json) {
    if (json) {
      let deviceHolder = [];
      json.forEach((data) => {
        let deviceBody = {
          status: data.status,
          name: data.name,
          ieeeAddr: data.ieeeAddr,
          devId: data.devId,
          cIds: this.getCidAndType(data.attributes, 0),
          types: this.getCidAndType(data.attributes, 1),
          environment: data.environment || {
            _id: "GERALd8ydXqOcrIR8vmR2zeO",
            name: "Sem Ambientes",
          },
        };

        deviceHolder.push(deviceBody);
      });

      return deviceHolder;
    }
    return {
      status: null,
      name: null,
      ieeeAddr: null,
      devId: null,
      cIds: null,
      types: null,
      environment: null,
    };
  }

  static addDevicesToEnv(devices, environments) {
    let envHolder = [];
    environments.forEach((environment) => {
      let deviceHolder = [];
      devices.forEach((device, index) => {
        if (device.environment._id === environment.id) {
          deviceHolder.push({
            status: device.status,
            name: device.name,
            ieeeAddr: device.ieeeAddr,
            devId: device.devId,
            cIds: device.cIds,
            types: device.types,
          });
        }
      });

      envHolder.push({
        name: environment.name,
        id: environment.id,
        devices: deviceHolder,
      });
    });

    return envHolder;
  }

  static formatEnvs(deviceHolder) {
    let envHolder = [];
    deviceHolder.map((data) => {
      let environmentBody = {
        id: data.environment._id,
        name: data.environment.name,
      };
      envHolder.push(environmentBody);
    });

    return envHolder.filter(
      (data, index, array) =>
        array.findIndex(
          (value) => value.id === data.id && value.name === data.name
        ) === index
    );
  }
}
